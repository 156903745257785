import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Container,
  Col,
  List,
  Row,
} from 'reactstrap';
import StartFreeTrial from './StartFreeTrial';

const PricingSection = ({ onFreeTrialClick = null }) => (
  <div className="section pricing" id="pricing">
    <Container>
      <Row>
        <Col className="text-center pb-5">
          <h2 className="title">
            <FormattedMessage
              defaultMessage="Pricing"
            />
          </h2>
          <p>
            <FormattedMessage
              defaultMessage="Get started today with a FREE trial!"
            />
          </p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md="6">
          <Card color="success">
            <CardHeader className="pb-0">
              <h3 className="my-0 text-white text-shadow">
                <FormattedMessage
                  defaultMessage="Free Trial"
                />
              </h3>
            </CardHeader>
            <CardBody className="text-shadow pt-0">
              <CardTitle className="text-white m-0" tag="h2">
                <FormattedMessage
                  defaultMessage="Free"
                />
              </CardTitle>
              <List type="unstyled" className="mb-0 text-white">
                <li>
                  <FormattedMessage
                    defaultMessage="5 applications active for 30 days"
                  />
                </li>
              </List>
            </CardBody>
            <CardFooter className="border-0">
              <StartFreeTrial
                className="btn-outline-white text-nowrap"
                outline
                onClick={onFreeTrialClick}
              />
            </CardFooter>
          </Card>
        </Col>
        <Col md="6">
          <Card className="base-bg">
            <CardHeader className="pb-0">
              <h3 className="my-0 text-white text-shadow">
                <FormattedMessage
                  defaultMessage="Unlimited"
                />
              </h3>
            </CardHeader>
            <CardBody className="text-shadow pt-0">
              <CardTitle className="text-white m-0" tag="h2">
                {/* eslint-disable-next-line react/style-prop-object */}
                <FormattedNumber value={99} style="currency" currency="AUD" minimumFractionDigits={0} />
              </CardTitle>
              <List type="unstyled" className="mb-0 text-white">
                <li>
                  <FormattedMessage
                    defaultMessage="Unlimited applications active for 30 days"
                  />
                </li>
              </List>
            </CardBody>
            <CardFooter className="border-0">
              <StartFreeTrial
                className="btn-outline-white text-nowrap"
                outline
                onClick={onFreeTrialClick}
              />
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
);

export default PricingSection;
