import React from 'react';
import { FormattedMessage } from 'react-intl';
import parsePhoneNumber from 'libphonenumber-js';
import { Col, Container, Row } from 'reactstrap';
import CallIconSvg from '../../assets/call.svg';

// es6 destructuring cause issue
// https://github.com/mrsteele/dotenv-webpack/issues/70
// use new line for each constant
const { TELEPLY_DEFAULT_VOICE_NUMBER } = process.env;

const TrialSection = () => (
  <div className="section trial-section">
    <Container>
      <Row className="align-items-center">
        <Col md="12" lg="5">
          <h2>
            <FormattedMessage
              defaultMessage="Give it a try!"
            />
          </h2>
        </Col>
        <Col md="12" lg="7">
          <div className="call">
            <div className="call-icon">
              <img src={CallIconSvg} alt="" />
            </div>
            <div className="call-info">
              <FormattedMessage
                defaultMessage="Call {phone} and enter job reference number
                {refNumber}"
                values={{
                  phone: <a href={`tel:${TELEPLY_DEFAULT_VOICE_NUMBER}`}>{parsePhoneNumber(TELEPLY_DEFAULT_VOICE_NUMBER, 'AU').formatNational()}</a>,
                  refNumber: <span className="base-color">2528</span>,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default TrialSection;
