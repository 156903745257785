import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const AccountSetupSuccessModal = ({
  isOpen = false,
  onClose = null,
}) => (
  <Modal id="reference-number" isOpen={isOpen} toggle={onClose} centered size="lg">
    <ModalHeader toggle={onClose} className="base-bg" tag="div">
      <h3 className="modal-title">
        <FormattedMessage
          defaultMessage="Account setup successful {icon}"
          values={{
            icon: (
              <svg className="ml-3" width="28" height="28" viewBox="0 0 28 28" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.3067 2.40346L8.84341 8.86679C8.41175 9.29846 8.16675 9.89346 8.16675 10.5118V22.1668C8.16675 23.4501 9.21675 24.5001 10.5001 24.5001H21.0001C21.9334 24.5001 22.7734 23.9401 23.1467 23.0885L26.9501 14.2101C27.9301 11.9001 26.2384 9.33346 23.7301 9.33346H17.1384L18.2467 3.99013C18.3634 3.40679 18.1884 2.81179 17.7684 2.39179C17.0801 1.71513 15.9834 1.71513 15.3067 2.40346ZM3.50008 24.5001C4.78341 24.5001 5.83341 23.4501 5.83341 22.1668V12.8335C5.83341 11.5501 4.78341 10.5001 3.50008 10.5001C2.21675 10.5001 1.16675 11.5501 1.16675 12.8335V22.1668C1.16675 23.4501 2.21675 24.5001 3.50008 24.5001Z"
                />
              </svg>
            ),
          }}
        />
      </h3>
    </ModalHeader>
    <ModalBody>
      <div className="bg-light call-info">
        <FormattedMessage
          defaultMessage="Your account has been created. Please check your email for instructions on how to login and complete the setup of your account."
        />
      </div>
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={onClose}>
        <FormattedMessage
          defaultMessage="Close"
        />
      </Button>
    </ModalFooter>
  </Modal>
);

export default AccountSetupSuccessModal;
