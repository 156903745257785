import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import StartFreeTrial from './StartFreeTrial';

// es6 destructuring cause issue
// https://github.com/mrsteele/dotenv-webpack/issues/70
// use new line for each constant
const { TELEPLY_LOGIN_LINK } = process.env;

const Navigation = ({
  id = null,
  navbar = false,
  className = null,
  homeLinkMessage = null,
  onFreeTrialClick = null,
}) => (
  <Nav id={id} navbar={navbar} className={className}>
    <NavItem>
      <NavLink href="#home">
        {homeLinkMessage}
        {!homeLinkMessage && (
          <FormattedMessage
            defaultMessage="Home"
          />
        )}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink href="#works">
        <FormattedMessage
          defaultMessage="How it works"
        />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink href="#faqs">
        <FormattedMessage
          defaultMessage="FAQs"
        />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink href="#pricing">
        <FormattedMessage
          defaultMessage="Pricing"
        />
      </NavLink>
    </NavItem>
    <StartFreeTrial
      className="btn-outline-white"
      outline
      onClick={onFreeTrialClick}
    />
    <NavItem>
      <NavLink href={TELEPLY_LOGIN_LINK}>
        <FormattedMessage
          defaultMessage="Login"
        />
      </NavLink>
    </NavItem>
  </Nav>
);

export default Navigation;
