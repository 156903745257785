import ReactDOM from 'react-dom';
import React from 'react';
import { Settings } from 'luxon';
import App from './js/App';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './scss/styles.scss';

const div = document.createElement('div');
div.id = 'landing';
document.body.appendChild(div);
Settings.defaultLocale = 'en-AU';

ReactDOM.render(
  <App />,
  div,
);
