import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import Navigation from './Navigation';

const Footer = ({ onFreeTrialClick = null }) => (
  <footer className="footer base-bg">
    <Container fluid>
      <Row className="align-items-center">
        <Col md="12" lg="5">
          <div className="copyright">
            <FormattedMessage
              defaultMessage="2021 All rights reserved. Leading Internet Solutions"
            />
          </div>
        </Col>
        <Col md="12" lg="7" className="footer-menu">
          <Navigation
            homeLinkMessage={(
              <FormattedMessage
                defaultMessage="Welcome"
              />
            )}
            onFreeTrialClick={onFreeTrialClick}
          />
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
