import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import StartFreeTrial from './StartFreeTrial';
import Step1Svg from '../../assets/step-1.svg';
import Step2Svg from '../../assets/step-2.svg';
import Step3Svg from '../../assets/step-3.svg';

const WorksSection = ({ onFreeTrialClick = null }) => (
  <div id="works" className="section works-section bg-light">
    <Container>
      <div className="text-center py-4">
        <h2 className="title">
          <FormattedMessage
            defaultMessage="How VoiceApply works"
          />
        </h2>
        <p>
          <FormattedMessage
            defaultMessage="The VoiceApply hiring process"
          />
        </p>
      </div>
      <Row className="align-items-center works-step">
        <Col md="6">
          <div className="step-description-left">
            <h2 className="step-title">
              <FormattedMessage
                defaultMessage="Step 1"
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="Place your job add with any existing platform and accept resumes as you <b>normally would</b>."
                values={{
                  b: (chunks) => <strong className="text-dark">{chunks}</strong>,
                }}
              />
            </p>
            <div className="button-group">
              <StartFreeTrial
                color="primary"
                onClick={onFreeTrialClick}
              />
            </div>
          </div>
        </Col>
        <Col md="6">
          <img src={Step1Svg} alt="" />
        </Col>
      </Row>
      <Row className="align-items-center works-step">
        <Col md="6">
          <img src={Step2Svg} alt="" />
        </Col>
        <Col md="6">
          <div className="step-description-right">
            <h2 className="step-title">
              <FormattedMessage
                defaultMessage="Step 2"
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="After receiving resumes you request candidates to call the VoiceApply system and enter your <b>unique job reference number</b>."
                values={{
                  b: (chunks) => <strong className="text-dark">{chunks}</strong>,
                }}
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="They are greeted by your personal recording and <b>leave a message</b> why they think they would be a good fit for your role."
                values={{
                  b: (chunks) => <strong className="text-dark">{chunks}</strong>,
                }}
              />
            </p>
            <div className="button-group">
              <StartFreeTrial
                color="primary"
                onClick={onFreeTrialClick}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center works-step pb-0">
        <Col md="6">
          <div className="step-description-left">
            <h2 className="step-title">
              <FormattedMessage
                defaultMessage="Step 3"
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="You can review each candidates voice message on your <b>VoiceApply Dashboard</b>."
                values={{
                  b: (chunks) => <strong className="text-dark">{chunks}</strong>,
                }}
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="Instead of reading hundreds of resumes, filter candidates on what matters most, their phone manner."
              />
            </p>
            <div className="button-group">
              <StartFreeTrial
                color="primary"
                onClick={onFreeTrialClick}
              />
            </div>
          </div>
        </Col>
        <Col md="6">
          <img src={Step3Svg} alt="" />
        </Col>
      </Row>
    </Container>
  </div>
);

export default WorksSection;
