import React, { useState, Fragment } from 'react';
import Header from '../components/Header';
import HeroBannerSection from '../components/HeroBannerSection';
import CustomerSection from '../components/CustomerSection';
import WorksSection from '../components/WorksSection';
import TrialSection from '../components/TrialSection';
import DashboardScreenSection from '../components/DashboardScreenSection';
import FaqsSection from '../components/FaqsSection';
import PricingSection from '../components/PricingSection';
import Footer from '../components/Footer';
import BackToTop from '../components/BackToTop';
import StartFreeTrialModal from '../components/StartFreeTrialModal';
import AccountSetupSuccessModal from '../components/AccountSetupSuccessModal';
import useCustomScrollListeners from '../hooks/useCustomScrollListeners';

const START_FREE_TRIAL_MODAL = 'START_FREE_TRIAL_MODAL';
const ACCOUNT_SETUP_SUCCESS_MODAL = 'ACCOUNT_SETUP_SUCCESS_MODAL';

const Landing = () => {
  const [openModalName, setOpenModalName] = useState(null);
  const [{
    refNumber = null,
    employerEmail = null,
    teleplyVoiceNumber = null,
  }, setNewCampaignData] = useState({});
  const hideAllModals = () => setOpenModalName(null);
  const showFreeTrialModal = () => setOpenModalName(START_FREE_TRIAL_MODAL);
  const showAccountSetupSuccessModal = () => setOpenModalName(ACCOUNT_SETUP_SUCCESS_MODAL);
  const { windowScrollY: scrollY } = useCustomScrollListeners();

  return (
    <Fragment>
      <Header onFreeTrialClick={showFreeTrialModal} isSticky={scrollY > 100} />
      <HeroBannerSection onFreeTrialClick={showFreeTrialModal} />
      <CustomerSection />
      <WorksSection onFreeTrialClick={showFreeTrialModal} />
      <TrialSection />
      <DashboardScreenSection />
      <FaqsSection />
      <PricingSection onFreeTrialClick={showFreeTrialModal} />
      <Footer onFreeTrialClick={showFreeTrialModal} />
      <BackToTop windowScrollY={scrollY} />
      <StartFreeTrialModal
        isOpen={openModalName === START_FREE_TRIAL_MODAL}
        onGetRefClick={(data) => {
          setNewCampaignData(data);
          showAccountSetupSuccessModal();
        }}
        onClose={hideAllModals}
      />
      <AccountSetupSuccessModal
        isOpen={openModalName === ACCOUNT_SETUP_SUCCESS_MODAL}
        refNumber={refNumber}
        employerEmail={employerEmail}
        teleplyVoiceNumber={teleplyVoiceNumber}
        onClose={hideAllModals}
      />
    </Fragment>
  );
};

export default Landing;
