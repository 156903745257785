import React, { useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap';
import Navigation from './Navigation';
import Logo from '../../assets/voiceapply.svg';

const Header = ({ isSticky = false, onFreeTrialClick = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <header className={`base-bg ${isSticky ? 'sticky' : ''}`}>
      <Container>
        <Navbar dark expand="md" className="header-navbar base-bg">
          <NavbarBrand href="/" className="col-auto p-0">
            <img className="logo" src={Logo} alt="" width="auto" height="76" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} className="d-lg-none collapsed" />
          <Collapse isOpen={isOpen} navbar>
            <Navigation
              id="main-menu"
              navbar
              className="mr-auto d-lg-flex justify-content-end flex-row col"
              onFreeTrialClick={onFreeTrialClick}
            />
          </Collapse>
        </Navbar>
      </Container>
    </header>
  );
};

export default Header;
