import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import Slider from 'react-slick';
import ClientLogoSeekSvg from '../../assets/client-logo-seek.svg';
import ClientLinkedInSvg from '../../assets/client-logo-linkedin.svg';
import ClientGumtreeSvg from '../../assets/client-logo-gumtree.svg';
import ClientMonsterSvg from '../../assets/client-logo-monster.svg';
import ClientIndeedSvg from '../../assets/client-logo-indeed.svg';
import CustomerImgSvg from '../../assets/customer-img.svg';

const CustomerSection = () => (
  <div className="section customer-section">
    <Container>
      <Slider
        slidesToShow={5}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={3000}
        arrows={false}
        dots={false}
        pauseOnHover
        responsive={[{
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
          },
        }, {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 2,
          },
        }]}
        className="customer-logos"
      >
        <img src={ClientLogoSeekSvg} alt="" />
        <img src={ClientLinkedInSvg} alt="" />
        <img src={ClientGumtreeSvg} alt="" />
        <img src={ClientMonsterSvg} alt="" />
        <img src={ClientIndeedSvg} alt="" />
        <img src={ClientLogoSeekSvg} alt="" />
        <img src={ClientLinkedInSvg} alt="" />
        <img src={ClientGumtreeSvg} alt="" />
        <img src={ClientMonsterSvg} alt="" />
        <img src={ClientIndeedSvg} alt="" />
      </Slider>
      <div className="customer-container">
        <Row className="align-items-center">
          <Col md="12" lg="7">
            <img src={CustomerImgSvg} alt="" />
          </Col>
          <Col md="12" lg="5">
            <h2>
              <FormattedMessage
                defaultMessage="Save time & money!"
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="Traditionally you receive large volume of candidates with great experience, however after 2 minutes on the phone its clear their phone manner rules them out. VoiceApply saves you time by allowing you to listen to candidates first, then move them to the interview stage."
              />
            </p>
          </Col>

        </Row>
      </div>
    </Container>
  </div>
);

export default CustomerSection;
