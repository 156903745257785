import React from 'react';
import { IntlProvider } from 'react-intl';
import Landing from './pages/Landing';

const App = () => (
  <IntlProvider locale="en-AU" defaultLocale="en-AU">
    <Landing />
  </IntlProvider>
);

export default App;
