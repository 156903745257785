import { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

const useCustomScrollListeners = () => {
  const [windowScrollY, setWindowScroll] = useState(0);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // track vertical scroll position
    window.addEventListener('scroll', () => {
      setWindowScroll(window.scrollY);
    });

    // smooth scroll on link with hash href
    Array.from(document.querySelectorAll('a[href^="#"]')).forEach((link) => {
      link.addEventListener('click', (e) => {
        const { currentTarget } = e;
        const scrollTarget = document.querySelector(currentTarget.getAttribute('href'));
        const header = document.querySelector('header');
        let position = scrollTarget.offsetTop;

        if (window.scrollY > 100) {
          position -= header.offsetHeight;
        } else {
          position -= (window.matchMedia('(min-width: 990px)').matches) ? header.offsetHeight * 1.65 : header.offsetHeight * 1.8;
        }
        scroll.scrollTo(Math.max(position, 0));

        e.preventDefault();
        return false;
      });
    });
  }, [setWindowScroll]);

  return {
    windowScrollY,
  };
};

export default useCustomScrollListeners;
