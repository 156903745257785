import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { camelize } from 'humps';
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  CustomInput,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';

// es6 destructuring cause issue
// https://github.com/mrsteele/dotenv-webpack/issues/70
// use new line for each constant
const { TELEPLY_DEFAULT_VOICE_NUMBER } = process.env;
const { TELEPLY_DEFAULT_VOICE_NUMBER_LABEL } = process.env;
const { USER_DEFAULT_COUNTRY_CODE } = process.env;

const StartFreeTrialForm = ({
  inModal = true,
  onSubmit = null,
  disabled = false,
  isUpdating = false,
  isError = false,
  serverError = null,
}) => {
  const { hasOwnProperty } = Object.prototype;
  const BodyWrapper = (inModal) ? ModalBody : Fragment;
  const FooterWrapper = (inModal) ? ModalFooter : Fragment;

  // input validation rules
  const positionNameRules = {
    required: true,
    minLength: 3,
    maxLength: 50,
  };
  const fullNameRules = {
    required: true,
    minLength: 3,
    maxLength: 200,
  };
  const emailRules = {
    required: true,
    minLength: 3,
    maxLength: 200,
  };

  // form hooks
  const {
    register, handleSubmit, errors, setError,
  } = useForm({
    defaultValues: {
      email: null,
      defaultCountryCode: USER_DEFAULT_COUNTRY_CODE,
      defaultVoiceNumber: TELEPLY_DEFAULT_VOICE_NUMBER,
      positionName: null,
      fullName: null,
      invCode: null,
    },
  });

  // register hidden input once
  useEffect(() => {
    register('defaultCountryCode');
    register('invCodeToggled');
    register('invCode');
  }, [register]);

  // pass RTK query errors to react hook form
  useEffect(() => {
    if (serverError?.status === 422) {
      serverError?.data?.invalidFields.forEach(({ name, message }) => {
        setError(camelize(name), { type: 'manual', message });
      });
    }
  }, [serverError, setError]);

  const onHandleSubmit = (typeof onSubmit === 'function') ? onSubmit : () => false;

  return (
    <Form id="start-free-trial-form" onSubmit={handleSubmit(onHandleSubmit)}>
      <BodyWrapper>
        <div className="form-row align-items-end">
          <Col md="12" lg="6" tag={FormGroup}>
            <Label for="defaultVoiceNumber">
              <FormattedMessage defaultMessage="Select Country / Phone Number" />
            </Label>
            <CustomInput
              id="defaultVoiceNumber"
              name="defaultVoiceNumber"
              type="select"
              invalid={hasOwnProperty.call(errors, 'defaultVoiceNumber')}
              innerRef={register({ required: true })}
              disabled={disabled}
            >
              <option value="">Select a country / phone number</option>
              <option value={TELEPLY_DEFAULT_VOICE_NUMBER}>
                {TELEPLY_DEFAULT_VOICE_NUMBER_LABEL}
              </option>
              <option value="+14159172088">
                US / +1 415 9172 088
              </option>
              <option value="+918009190665">
                IN / +91 800 919 0665
              </option>
            </CustomInput>
            <FormFeedback>
              {errors.defaultVoiceNumber?.type === 'required' && (
                <FormattedMessage
                  defaultMessage="Please choose phone number."
                />
              )}

              {/* other manual errors */}
              {errors.defaultVoiceNumber?.message}
            </FormFeedback>
          </Col>
          <Col md="12" lg="6" tag={FormGroup}>
            <FormattedMessage
              defaultMessage="Position Name"
            >
              {(chunks) => (
                <Fragment>
                  <Label>{chunks}</Label>
                  <Input
                    name="positionName"
                    placeholder={chunks}
                    innerRef={register(positionNameRules)}
                    invalid={hasOwnProperty.call(errors, 'positionName')}
                    maxLength={positionNameRules.maxLength}
                    disabled={disabled}
                  />
                </Fragment>
              )}
            </FormattedMessage>
            <FormFeedback>
              {/* empty input */}
              {errors.positionName?.type === 'required' && (
                <FormattedMessage defaultMessage="Please enter position name." />
              )}
              {/* too short */}
              {errors.positionName?.type === 'minLength' && (
                <FormattedMessage
                  defaultMessage="Position name must be at least {minLength} chars."
                  values={positionNameRules}
                />
              )}
              {/* too long, never happens, but anyway  */}
              {errors.positionName?.type === 'maxLength' && (
                <FormattedMessage
                  defaultMessage="Position name must be less than {maxLength} chars."
                  values={positionNameRules}
                />
              )}
              {/* other manual errors */}
              {errors.positionName?.message}
            </FormFeedback>
          </Col>
        </div>
        <div className="form-row">
          <Col md="12" lg="6" tag={FormGroup}>
            <FormattedMessage
              defaultMessage="Full name"
            >
              {(chunks) => (
                <Fragment>
                  <Label>{chunks}</Label>
                  <Input
                    name="fullName"
                    placeholder={chunks}
                    innerRef={register(fullNameRules)}
                    invalid={hasOwnProperty.call(errors, 'fullName')}
                    maxLength={fullNameRules.maxLength}
                    disabled={disabled}
                  />
                </Fragment>
              )}
            </FormattedMessage>
            <FormFeedback>
              {/* empty input */}
              {errors.fullName?.type === 'required' && (
                <FormattedMessage defaultMessage="Please enter full name." />
              )}
              {/* too short */}
              {errors.fullName?.type === 'minLength' && (
                <FormattedMessage
                  defaultMessage="Full name must be at least {minLength} chars."
                  values={fullNameRules}
                />
              )}
              {/* too long, never happens, but anyway  */}
              {errors.fullName?.type === 'maxLength' && (
                <FormattedMessage
                  defaultMessage="Full name must be less than {maxLength} chars."
                  values={fullNameRules}
                />
              )}
              {/* other manual errors */}
              {errors.fullName?.message}
            </FormFeedback>
          </Col>
          <Col md="12" lg="6" tag={FormGroup}>
            <FormattedMessage
              defaultMessage="Email address"
            >
              {(chunks) => (
                <>
                  <Label>{chunks}</Label>
                  <Input
                    name="email"
                    type="email"
                    placeholder={chunks}
                    innerRef={register(emailRules)}
                    invalid={hasOwnProperty.call(errors, 'email')}
                    maxLength={emailRules.maxLength}
                    disabled={disabled}
                  />
                </>
              )}
            </FormattedMessage>
            <FormFeedback>
              {/* empty input */}
              {errors.email?.type === 'required' && (
                <FormattedMessage defaultMessage="Please enter email." />
              )}
              {/* too short */}
              {errors.email?.type === 'minLength' && (
                <FormattedMessage
                  defaultMessage="Email must be at least {minLength} chars."
                  values={emailRules}
                />
              )}
              {/* too long, never happens, but anyway  */}
              {errors.email?.type === 'maxLength' && (
                <FormattedMessage
                  defaultMessage="Email must be less than {maxLength} chars."
                  values={emailRules}
                />
              )}
              {/* other manual errors */}
              {errors.email?.message}
            </FormFeedback>
          </Col>
        </div>
        {/* unexpected server error */}
        {isError && serverError.status !== 422 && (
          <Alert color="danger">
            <FormattedMessage
              defaultMessage="Oops, something went wrong. <div>Error Code: {errorCode}</div> <div>Error Description: {errorDescription}</div>"
              values={{
                div: (chunks) => <div>{chunks}</div>,
                errorCode: serverError.status,
                errorDescription: serverError.data.errorDescription || serverError.data.error,
              }}
            />
          </Alert>
        )}
      </BodyWrapper>
      <FooterWrapper className="d-flex justify-content-end">
        <Button
          color="primary"
          className="reference-number"
          type="submit"
          disabled={disabled}
        >
          <FormattedMessage
            defaultMessage="Get reference number now {icon}"
            values={{
              icon: isUpdating
                ? <Spinner size="sm" />
                : (
                  <svg className="ml-2 mr-0" fill="white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.2275 11.0079L5.2175 11.0079C4.6675 11.0079 4.2175 11.4579 4.2175 12.0079C4.2175 12.5579 4.6675 13.0079 5.2175 13.0079L15.2275 13.0079L15.2275 15.7979C15.2275 16.2479 15.7675 16.4679 16.0775 16.1479L19.8575 12.3579C20.0475 12.1579 20.0475 11.8479 19.8575 11.6479L16.0775 7.85789C15.7675 7.53789 15.2275 7.76789 15.2275 8.20789L15.2275 11.0079Z"
                    />
                  </svg>
                ),
            }}
          />
        </Button>
      </FooterWrapper>
    </Form>
  );
};

export default StartFreeTrialForm;
