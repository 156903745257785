import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  Modal,
  ModalHeader,
} from 'reactstrap';
import StartFreeTrialForm from './StartFreeTrialForm';

const { API_BASE_URL } = process.env;
// API request handler
const postUser = async (data) => {
  const response = await fetch(`${API_BASE_URL}signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await response.json();
  if (!response.ok) {
    const error = {
      status: response.status,
      data: camelizeKeys(json),
    };
    throw error;
  }
  return camelizeKeys(json);
};

/* eslint-disable arrow-body-style */
const StartFreeTrialModal = ({
  isOpen = false,
  onClose = null,
  onGetRefClick = null,
}) => {
  const initialRequestState = {
    isError: false,
    isUpdating: false,
    error: null,
    data: null,
    isSuccess: false,
  };
  // use RTK query pattern
  const [{
    isError,
    isUpdating,
    error,
    data,
    isSuccess,
  }, setRequestResult] = useState({ ...initialRequestState });

  useEffect(() => {
    if (isSuccess) {
      onGetRefClick(data);
    }
  }, [data, isSuccess]);

  const onSubmit = ({ invCode, invCodeToggled, ...rest }) => {
    const payload = {
      ...rest,
      invCode: (invCodeToggled) ? invCode : null,
    };

    // show loading spinner
    setRequestResult({
      ...initialRequestState,
      isUpdating: true,
    });

    // call api
    postUser(decamelizeKeys(payload))
      .then((campaign) => {
        const { id: refNumber, teleplyVoiceNumber, employerEmail } = campaign;

        setRequestResult({
          ...initialRequestState,
          isUpdating: false,
          isSuccess: true,
          data: {
            refNumber,
            teleplyVoiceNumber,
            employerEmail,
          },
        });
      })
      .catch(({ status, data: responseBody }) => {
        // show error
        setRequestResult({
          ...initialRequestState,
          isError: true,
          error: { data: responseBody, status },
        });
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered size="lg">
      <ModalHeader toggle={onClose} className="base-bg" tag="div">
        <h3 className="modal-title">
          <FormattedMessage
            defaultMessage="Start free trial"
          />
        </h3>
      </ModalHeader>
      <StartFreeTrialForm
        onSubmit={onSubmit}
        disabled={isUpdating || isSuccess}
        isUpdating={isUpdating}
        isError={isError}
        serverError={error}
        inModal
      />
    </Modal>
  );
};

export default StartFreeTrialModal;
