import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import PlayIconPng from '../../assets/play-icon.png';
import DashboardScreeningSvg from '../../assets/website-screen-1.png';

const DashboardScreenSection = () => (
  <div className="section dashboard-screen">
    <Container fluid>
      <Row noGutters className="align-items-center">
        <Col md="6" lg="4">
          <div className="description">
            <img src={PlayIconPng} className="play-icon" alt="" />
            <h2>
              <FormattedMessage
                defaultMessage="Listen to the candidate"
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="Manage applications via the voice screening dashboard."
              />
            </p>
          </div>
        </Col>
        <Col md="6" lg="8">
          <img src={DashboardScreeningSvg} className="dashboard-screen-img" alt="" />
        </Col>
      </Row>
    </Container>
  </div>
);

export default DashboardScreenSection;
