import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Fade } from 'reactstrap';
import BackToTopSvg from '../../assets/back-to-top.svg';

const BackToTop = ({ windowScrollY = null, minFadeInY = 100 }) => (
  <Fade in={windowScrollY > minFadeInY}>
    <button
      className="btn back-to-top p-0 border-0"
      type="button"
      onClick={() => { scroll.scrollToTop(); }}
    >
      <img src={BackToTopSvg} alt="" />
    </button>
  </Fade>
);

export default BackToTop;
