import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Col,
  Collapse,
  Container,
  Row,
} from 'reactstrap';

const FaqsSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="section faqs base-bg" id="faqs">
      <Container>
        <Row>
          <Col>
            <div className="text-center">
              <h2 className="title">
                <FormattedMessage
                  defaultMessage="Frequently asked questions"
                />
              </h2>
              <p className="mb-4">
                <FormattedMessage
                  defaultMessage="Answers to everything you need to know"
                />
              </p>
              <Button onClick={toggle} className={`btn-outline-white ${isOpen ? '' : 'collapsed'}`}>
                <span className="faq-close">
                  <FormattedMessage
                    defaultMessage="Open FAQs"
                  />
                </span>
                <span className="faq-open">
                  <FormattedMessage
                    defaultMessage="Close FAQs"
                  />
                </span>
                <svg className="arrow" width="30" height="29" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.0334 15.4831L20.5 21.9498L26.9667 15.4831C27.6167 14.8331 28.6667 14.8331 29.3167 15.4831C29.9667 16.1331 29.9667 17.1831 29.3167 17.8331L21.6667 25.4831C21.0167 26.1331 19.9667 26.1331 19.3167 25.4831L11.6667 17.8331C11.0167 17.1831 11.0167 16.1331 11.6667 15.4831C12.3167 14.8498 13.3834 14.8331 14.0334 15.4831Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </div>
            <Collapse isOpen={isOpen} className="faqs-questions">
              <h3>
                <FormattedMessage
                  defaultMessage="What does the free trial cover?"
                />
              </h3>
              <p>
                <FormattedMessage
                  defaultMessage="Your trial will last for 7 days and a maximum of five inbound calls. During this trial you will still receive the full features of our paid service. On signup you will be emailed instructions on how to activate your service should you want to continue after the trail period."
                />
              </p>
              <hr />

              <h3>
                <FormattedMessage
                  defaultMessage="How do i activate my subscription from free to the paid version?"
                />
              </h3>
              <p>
                <FormattedMessage
                  defaultMessage="Once you signup for a free trail you will be sent login details to your dashboard. From the dashboard you can activate or extend your subscription."
                />
              </p>
              <hr />

              <h3>
                <FormattedMessage
                  defaultMessage="When a candidate calls, what message do they hear?"
                />
              </h3>
              <p>
                <FormattedMessage
                  defaultMessage="During the signup process you will be prompted to create a greeting for your candidates. You can do this by having our system call you or you can upload an mp3 file. This greeting can be altered at any time in the future as well. Once your account is active you also have the ability to request individual recordings of their name / email and optionally provide them preparation time before having to record an application."
                />
              </p>
              <hr />

              <h3>
                <FormattedMessage
                  defaultMessage="Does everyone use the same generic number?"
                />
              </h3>
              <p>
                <FormattedMessage
                  defaultMessage="Yes. When your candidate first calls they will be prompted to enter your unique reference number provided in your job ad. Once this reference number is entered your greeting will play and a recording will start."
                />
              </p>
              <hr />

              <h3>
                <FormattedMessage
                  defaultMessage="How do i get my reference number?"
                />
              </h3>
              <p>
                <FormattedMessage
                  defaultMessage="You are allocated a unique reference number after signing up for a free trial below."
                />
              </p>
              <hr />

              <h3>
                <FormattedMessage
                  defaultMessage="How many applications can I receive?"
                />
              </h3>
              <p>
                <FormattedMessage
                  defaultMessage="There is no limit to the number of applications you can receive while your campaign is active."
                />
              </p>
              <hr />

              <h3>
                <FormattedMessage
                  defaultMessage="Can i edit my recording after i have completed signing up?"
                />
              </h3>
              <p>
                <FormattedMessage
                  defaultMessage="You can edit your greeting and application flow via your dashboard. After signing up you are provide with a username and password."
                />
              </p>
              <hr />

              <h3>
                <FormattedMessage
                  defaultMessage="If i need help, who can i contact?"
                />
              </h3>
              <p>
                <FormattedMessage
                  defaultMessage="At any time you can email our support team at <email>{supportEmail}</email>. We will endeavour to get back to you as soon as possible."
                  values={{
                    email: (chunks) => <a href={`mailto:${chunks}`} className="text-white">{chunks}</a>,
                    supportEmail: 'support@voiceapply.com',
                  }}
                />
              </p>
            </Collapse>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FaqsSection;
