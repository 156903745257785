import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap';
import StartFreeTrial from './StartFreeTrial';
import HeroBannerSvg from '../../assets/hero-banner.svg';

const HeroBannerSection = ({ onFreeTrialClick = null }) => (
  <div id="home" className="section hero base-bg">
    <Container>
      <div className="hero-banner">
        <h1 className="mb-3">
          <FormattedMessage
            defaultMessage="Hiring for phone based roles?"
          />
        </h1>
        <h2>
          <FormattedMessage
            defaultMessage="Save time and listen to voice {br}applications from your candidates."
            values={{
              br: <br />,
            }}
          />
        </h2>
        <a href="#works" className="btn btn-outline-white mr-2">
          <FormattedMessage
            defaultMessage="How it works"
          />
        </a>
        <StartFreeTrial
          color="success"
          onClick={onFreeTrialClick}
        />
        <img className="hero-img" src={HeroBannerSvg} alt="" />
      </div>
    </Container>
  </div>
);

export default HeroBannerSection;
