import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

const StartFreeTrial = ({
  className = null,
  color = 'secondary',
  outline = false,
  onClick = null,
}) => (
  <Button
    className={className}
    color={color}
    outline={outline}
    type="button"
    onClick={onClick}
  >
    <FormattedMessage
      defaultMessage="Start free trial"
    />
  </Button>
);

export default StartFreeTrial;
